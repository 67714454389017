<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ reportDisplayName }}</h1>
      <div class="btns-container">
        <div v-if="showReportTypeSelection">
          <VasionButton
            id="share"
            :classProp="'secondary'"
            :isDisabled="disableRunButtons"
            @vasionButtonClicked="generatePDF()"
          >
            SHARE
          </VasionButton>
        </div>
        <div v-else>
          <More
            :disableButtons="[!canScheduleReports, disableEditDeleteButton, disableRunButtons, disableEditDeleteButton]"
            :hideButton="[false, disableEditDeleteButton, false, false]"
            @shareClicked="generatePDF"
            @editClicked="editReportClick"
            @scheduledClicked="newSchedule"
            @deleteClicked="deleteReport"
          />
        </div>
        <VasionButton
          id="run-report"
          class="last-btn"
          :classProp="'primary'"
          :isDisabled="disableRunButtons"
          @vasionButtonClicked="runReport()"
        >
          Run Report
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <form @submit.prevent="runReport">
        <div class="md-layout">
          <div class="md-layout-item">
            <VasionDropList
              v-if="showReportTypeSelection"
              v-slot="slotItem"
              v-model="selectedReportType"
              :dataArray="reportTypes"
              width="100%"
              title="REPORT TYPE"
              type="plain-list"
              valueName="sName"
              displayName="sName"
            >
              {{ slotItem.item.sName }}
            </VasionDropList>
          </div>
          <div v-if="selectedReport && selectedReport.bSupportsIndexForm" class="md-layout-item">
            <VasionDropList
              v-slot="slotItem"
              v-model="indexFormSelection"
              :dataArray="selectedReport.oDropDownValues"
              :title="`${$formsLabel ? $formsLabel.toUpperCase() : 'Object'}`"
              type="plain-list"
              valueName="iID"
              width="100%"
              displayName="sName"
              @input="newIndexFormSelection"
            >
              {{ slotItem.item.sName }}
            </VasionDropList>
          </div>
          <div v-if="selectedReport && selectedReport.bSupportsDateRange" class="md-layout-item">
            <VasionDateTimeField
                id="from-date"
                v-model="fromDate"
              label="FROM DATE"
              type="date"
                :required="true"
              />
          </div>
          <div v-if="selectedReport && selectedReport.bSupportsDateRange" class="md-layout-item">
            <VasionDateTimeField
                id="to-date"
                v-model="toDate"
              label="THROUGH DATE"
              type="date"
                :required="true"
              />
          </div>
          <div v-if="selectedReport && selectedReport.bSupportsDropDown" class="md-layout-item">
            <VasionDroplist
              v-slot="slotItem"
              v-model="dropdownSelection"
              :dataArray="selectedReport.oDropDownValues"
              :title="selectedReport.sDropDownLabelText"
              type="plain-list"
              valueName="sName"
              displayName="sName"
              @input="newDropDownSelection"
            >
              {{ slotItem.item.sName }}
            </VasionDroplist>
          </div>
        </div>
      </form>
      <div v-if="errorText">
        {{ errorText }}
      </div>
      <div v-if="genericReport && genericReport.tableHeaders" :class="showReportTypeSelection ? 'results-table-container-type-selection': 'results-table-container'" class="vasion-html-table-default">
        <div v-if="genericReport.tableRows.length > 0">
          <VasionTable id="report-table" :headerColumns="genericReport.tableHeaders" :tableRows="genericReport.tableRows" />
        </div>
        <div v-else id="no-data-div">
          No data for the given report configuration
        </div>
      </div>
      <div v-else class="vasion-run-report-prompt">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Run a Report to view entries
        </div>
      </div>
    </div>
    
    <md-dialog id="confirmLeave" :md-active.sync="showDeleteDialog" @md-clicked-outside="cancelDelete()">
      <VasionConfirmationDialog
        message="Are you sure you want to delete this report?"
        @noButtonClick="cancelDelete()"
        @yesButtonClick="doDelete()"
      />
    </md-dialog>

    <VasionSnackbar
      id="delete-folder-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import More from '@/components/reporting/More.vue'

export default {
  name: 'TheGenericReport',
  components: {
    More,
  },
  data: function () {
    return {
      dropdownSelection: {},
      errorText: '',
      fromDate: '',
      indexFormSelection: {},
      selectedReport: {},
      selectedReportType: '',
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      toDate: '',
    }
  },
  computed: {
    canConfigureReports() { return this.$store.getters['common/canConfigureReports'] },
    canScheduleReports() { return this.$store.getters['common/canAccessScheduledReports'] },
    disableEditDeleteButton() { return !(this.canConfigureReports && !this.showReportTypeSelection) },
    disableRunButtons() {
      if (!this.selectedReport || !this.selectedReport.sName || this.selectedReport.sName === '') {
        return true
      }

      if (this.selectedReport.bSupportsDateRange === true && (this.fromDate === '' || this.toDate === '' || this.fromDate > this.toDate)) {
        return true
      }

      if (this.selectedReport.bSupportsIndexForm === true && typeof this.indexFormSelection.iID === 'undefined') {
        return true
      }

      if (this.selectedReport.bSupportsDropDown === true && typeof this.dropdownSelection.sName === 'undefined') {
        return true
      }

      return false
    },
    genericReport() { return this.$store.state.reporting.genericReport },
    genericReportForm() { return this.$store.state.reporting.genericReportForm },
    reportDisplayName() {
      switch (this.$route.params.reportType) {
        case 'user':
          return 'User'
        case 'document':
          return 'Document'
        case 'documentcontrol':
          return 'Document Control'
        default:
          return this.showReportTypeSelection === true ? '' : this.selectedCustomReport.sName
      }
    },
    reportTypes() {
      switch (this.$route.params.reportType) {
        case 'user':
          return this.$store.state.reporting.userReports
        case 'document':
          return this.$store.state.reporting.documentReports
        case 'documentcontrol':
          return this.$store.state.reporting.documentControlReports
        case 'custom':
          return this.$store.state.reporting.customReports
        default:
          return this.$store.state.reporting.customReports
      }
    },
    selectedCustomReport() { return this.$store.state.reporting.selectedRunCustomReport },
    showReportTypeSelection() { return !this.selectedCustomReport || !this.selectedCustomReport.sName || this.selectedCustomReport.sName === '' },
    users() { return this.$store.state.common.users },
  },
  watch: {
    reportDisplayName: function () { this.resetData() },
    selectedCustomReport: function () {
      this.selectedReportType = {
        sName: this.showReportTypeSelection === true ? '' : this.selectedCustomReport.sName,
      }
    },
    selectedReportType: function () {
      this.indexFormSelection = {}
      this.dropdownSelection = {}
      this.fromDate = ''
      this.toDate = ''
      this.selectedReport = {}

      this.selectedReport = this.reportTypes.find((obj) => obj.sName === this.selectedReportType.sName)
      if (this.genericReportForm && this.genericReportForm.attributeForm) {
          this.indexFormSelection = this.selectedReport.oDropDownValues.find(r => r.sName === this.genericReportForm.attributeForm.sName)
      }
    },
  },
  async created() {
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat

    this.$store.dispatch('reporting/resetReports')
    this.$store.dispatch('common/getUsers')
    this.$store.dispatch('reporting/loadReports', true)
    const payload = {
      schedulerTypes: 1223,
      orderBy: 0,
      ascendingOrder: true,
    }
    await this.$store.dispatch('automationEngine/getSchedulerList', payload)

    if (this.genericReportForm
      && this.genericReportForm.reportType
      && this.genericReportForm.reportType.sReportType
      && this.genericReportForm.reportType.sReportType.toLowerCase() === this.$route.params.reportType) {
      this.setReportingValues()
    }
    if (this.showReportTypeSelection === false) {
      this.selectedReportType = {
        sName: this.selectedCustomReport.sName,
      }
    }
  },
  methods: {
    buildPayload: function () {
      this.errorText = ''
      let payload
      if (!this.selectedReport || !this.selectedReport.sName) {
        this.errorText = 'Please select a Report Type'
        return payload
      }

      if (this.selectedReport.bSupportsDateRange && (this.fromDate === '' || this.toDate === '' || this.fromDate > this.toDate)) {
        this.errorText = 'Please enter a valid date range'
        return payload
      }

      if (this.selectedReport.bSupportsIndexForm && typeof this.indexFormSelection.iID === 'undefined') {
        this.errorText = `Please select ${$formsLabelWithPrefix}`
        return payload
      }

      if (this.selectedReport.bSupportsDropDown && typeof this.dropdownSelection.sName === 'undefined') {
        this.errorText = 'Please select a drop down value'
        return payload
      }

      let selectedValue = 0
      if (this.selectedReport.bSupportsIndexForm) {
        selectedValue = !this.indexFormSelection.iID ? 0 : this.indexFormSelection.iID
      } else if (this.selectedReport.bSupportsDropDown) {
        selectedValue = !this.dropdownSelection.sName ? 0 : this.dropdownSelection.sName
      }

      payload = {
        sName: this.selectedReport.sName,
        dtFromDate: !this.fromDate ? new Date() : this.fromDate,
        dtThroughDate: !this.toDate ? new Date() : this.toDate,
        iSelectedDropDownValue: selectedValue,
        sExportType: 'pdf',
      }

      return payload
    },
    cancelDelete() { this.showDeleteDialog = !this.showDeleteDialog },
    deleteReport() { this.showDeleteDialog = !this.showDeleteDialog },
    async doDelete() {
      const response = await this.$store.dispatch('reporting/deleteCustomReportByName', this.selectedCustomReport.sName)

      if (response.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarImage = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Report has been deleted'

        this.$store.dispatch('reporting/loadReports')
        this.$router.push({ name: 'TheEmptyReport' })
      }
    },
    editReportClick() {
      if (this.selectedReport && this.selectedReport.sName) {
        this.$store.dispatch('reporting/setSelectedReportConfigByName', this.selectedReport.sName);
        this.$router.push({ name: 'TheReportConfig' })
      }
    },
    async generatePDF() {
      const payload = this.buildPayload()

      if (payload) {
        this.$store.dispatch('reporting/generateGenericReportPDF', payload)
      }
      let reportType = 'Custom'
      switch (this.$route.params.reportType) {
        case 'user':
          reportType = 'User'
          break;
        case 'document':
        case 'documentcontrol':
          reportType = 'Document'
          break;
      }
      await this.$amplitude.trackEvent('Report Shared', {
        'reportType': reportType
      })

    },
    async logReportRun() {
      if (this.reportDisplayName === 'Document Control') return
      let reportType = this.reportDisplayName
      if (reportType === this.selectedReport.sName) reportType = 'Custom'

      const properties = {
        reportType,
        ...(reportType !== 'Custom' && { reportSubType: this.selectedReport.sName }),
      }
      
      await this.$amplitude.trackEvent('Report Run', properties)
    },
    newDropDownSelection: function (value) { this.dropdownSelection = value },
    newIndexFormSelection: function (value) { this.indexFormSelection = value },
    newSchedule() {
      const payload = {
        reportType: this.selectedReportType,
        attributeForm: this.indexFormSelection,
        fromDate: this.fromDate,
        toDate: this.toDate,
      }
      this.$store.dispatch('reporting/setGenericReportForm', payload)
      this.$router.push({ name: 'EditReportScheduledReporting', params: { schedulerId: 0 } })
    },
    resetData() {
      if (this.showReportTypeSelection === true) {
        this.selectedReportType = ''
        this.selectedReport = {}
      }

      this.$store.dispatch('reporting/resetReports')
      this.fromDate = ''
      this.toDate = ''
      this.dropdownSelection = {}
      this.indexFormSelection = {}
      this.errorText = ''
    },
    runReport: function () {
      const payload = this.buildPayload()
      if (payload) {
        this.logReportRun()
        this.$store.dispatch('reporting/runGenericReport', payload)
      }
    },
    setReportingValues() {
      this.selectedReportType = this.reportTypes.find(r => r.sName === this.genericReportForm.reportType.sName)
      this.fromDate = this.genericReportForm.fromDate
      this.toDate = this.genericReportForm.toDate
    },
  },
}
</script>

<style lang="scss" scoped>
.results-table-container-type-selection {
  overflow-y: auto;
  height: calc(100vh - 298px);
}

.results-table-container {
  overflow-y: auto;
  height: calc(100vh - 222px);
}
</style>
